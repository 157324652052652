import * as types from "./types";
import _ from "lodash";
const INITIAL_STATE = [
  {
    Id: 1,
    Name: "SHIFA KHAN",
    Designation: "Telemarketer",
    Description: `Hi, I'm Shifa, a motivated telemarketer with top-notch marketing and customer service abilities. Adept at making phone calls to successfully deliver a message and obtain information regarding properties. Helping families and individuals buy and sell properties gives me a tremendous deal of personal satisfaction and I would cherish nothing more than to have them become homeowners of their new residences.`,
    Image: "assets/img/staff/1.png",
    Facebook: "#",
    Linkedin: "#",
    Instagram: "#",
    Twitter: "#",
    IsActive: true,
  },
  {
    Id: 2,
    Name: "GULNAZ AHMAD",
    Designation: "Administrator",
    Description: `Hello, I’m Gulnaz Ahmad, a professional real estate administrator with over 5 years of experience in the field, who has a proven track record of assisting with the management of properties, coordinating with tenants and landlords, and handling administrative tasks. I am highly motivated and committed to helping clients achieve their goals. I am always eager to offer my knowledge to make sure that operations run smoothly.`,
    Image: "assets/img/staff/2.png",
    Facebook: "#",
    Linkedin: "#",
    Instagram: "#",
    Twitter: "#",
    IsActive: true,
  },
  {
    Id: 3,
    Name: "FARHANA NAEEMUDDIN",
    Designation: "Sales Manager",
    Description:
      "I am passionate about houses. I really love the variety of styles, architecture and the different ways people design and build homes. It just seemed natural for me to sell real estate where I could be involved with my passion and get paid for it. Really listening to what your clients want and finding a house that fits them perfectly is just a great feeling. One of the biggest compliments you can get is having a client get teary-eyed at the closing table.",
    Image: "assets/img/staff/3.png",
    Facebook: "#",
    Linkedin: "#",
    Instagram: "#",
    Twitter: "#",
    IsActive: true,
  },
  {
    Id: 4,
    Name: "BONIFACE FRANCIS",
    Designation: "Investment Adviser",
    Description:
      "With over 5 years in the dynamic Dubai real estate market, Boniface has successfully combined his extensive market knowledge with his exemplary interpersonal skills. He is a self-starter who is spontaneous and optimistic and easily adapts quickly. He has a solid relationship with all major developers in Dubai and this places him at perfect position to advise his clients professionally in their asset acquisition.",
    Image: "assets/img/staff/4.png",
    Facebook: "#",
    Linkedin: "#",
    Instagram: "#",
    Twitter: "#",
    IsActive: true,
  },
];
const Staffs = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_STAFF_LIST:
      return { ..._.mapKeys(action.payload, "id") };
    case types.FETCH_STAFF:
      return action.payload;

    default:
      return state;
  }
};

export default Staffs;
