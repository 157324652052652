import * as types from "./types";
import _ from "lodash";
const offering_type = [
  {
    label: "Commercial sale",
    value: "CS",
  },
  {
    label: "Residential sale",
    value: "RS",
  },
  {
    label: "Commercial rent",
    value: "CR",
  },
  {
    label: "Residential rent",
    value: "RR",
  },
];
const OfferingTypes = (state = offering_type, action) => {
  switch (action.type) {
    case types.FETCH_OFFERING_TYPE:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    default:
      return _.mapKeys(state, "value");
  }
};

export default OfferingTypes;
