import * as types from "./types";

const INITIAL_VALUES = {
  offeringType: "",
  propertyType: "",
  categoryType: "",
  price: "",
  priceMin: "",
  priceMax: "",
  city: "",
  size: "",
  bedroom: "",
  bathroom: "",
  furnished: "",
  priceOnApplication: "",
};
const PropertyFilters = (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case types.SET_OFFERING_TYPE:
      return { ...state, offeringType: action.payload };
    case types.SET_PROPERTY_TYPE:
      return { ...state, propertyType: action.payload };
    case types.SET_CATEGORY_TYPE:
      return { ...state, categoryType: action.payload };
    case types.SET_PRICE:
      return { ...state, price: action.payload };
    case types.SET_PRICE_MIN:
      return { ...state, priceMin: action.payload };
    case types.SET_PRICE_MAX:
      return { ...state, priceMax: action.payload };
    case types.SET_CITY:
      return { ...state, city: action.payload };
    case types.SET_SIZE:
      return { ...state, size: action.payload };
    case types.SET_BEDROOM:
      return { ...state, bedroom: action.payload };
    case types.SET_BATHROOM:
      return { ...state, bathroom: action.payload };
    case types.SET_FURNISHED:
      return { ...state, furnished: action.payload };
    case types.SET_PRICE_ON_APPLICATION:
      return { ...state, priceOnApplication: action.payload };
    default:
      return state;
  }
};
export default PropertyFilters;
