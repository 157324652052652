import * as types from "./types";

const INITIAL_VALUES = {
  propertyId: "",
  propertyReferenceNumber: "",
};
const GlobalValues = (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case types.SET_PROPERTY_REFERENCE_NO:
      return { ...state, propertyReferenceNumber: action.payload };
    default:
      return state;
  }
};

export default GlobalValues;
