import * as types from "./types";
import _ from "lodash";
const property_type = [
  {
    label: "Apartment",
    value: "AP",
  },
  // {
  //   label: "Apartment / Flat",
  //   value: "AP",
  // },
  // {
  //   label: "Bungalow",
  //   value: "BW",
  // },
  // {
  //   label: "Compound",
  //   value: "CD",
  // },
  // {
  //   label: "Duplex",
  //   value: "DX",
  // },
  // {
  //   label: "Full floor",
  //   value: "FF",
  // },
  // {
  //   label: "Half floor",
  //   value: "HF",
  // },
  // {
  //   label: "Land / Plot",
  //   value: "LP",
  // },
  {
    label: "Penthouse",
    value: "PH",
  },
  // {
  //   label: "Townhouse",
  //   value: "TH",
  // },
  {
    label: "Villa",
    value: "VH",
  },
  // {
  //   label: "Villa / House",
  //   value: "VH",
  // },
  // {
  //   label: "Whole Building",
  //   value: "WB",
  // },
  // {
  //   label: "Short Term / Hotel Apartment",
  //   value: "HA",
  // },
  // {
  //   label: "Labor camp",
  //   value: "LC",
  // },
  // {
  //   label: "Bulk units",
  //   value: "BU",
  // },
  // {
  //   label: "Warehouse",
  //   value: "WH",
  // },
  // {
  //   label: "Factory",
  //   value: "FA",
  // },
  {
    label: "Office fitted",
    value: "OF",
  },
  {
    label: "Office shell n core",
    value: "OSC",
  },
  // {
  //   label: "Office space",
  //   value: "OF",
  // },
  // {
  //   label: "Retail",
  //   value: "RE",
  // },
  // {
  //   label: "Plot",
  //   value: "LP",
  // },
  // {
  //   label: "Shop",
  //   value: "SH",
  // },
  // {
  //   label: "Show Room",
  //   value: "SR",
  // },
  // {
  //   label: "Staff Accommodation",
  //   value: "SA",
  // },
];
const PropertyTypes = (state = property_type, action) => {
  switch (action.type) {
    case types.FETCH_PROPERTY_TYPE:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    default:
      return _.mapKeys(state, "value");
  }
};

export default PropertyTypes;
