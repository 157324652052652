import { createStore, applyMiddleware, compose } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";

import { persistStore } from "redux-persist";
import reduxThunk from "redux-thunk";
import logger from "./middleware/logger";
// import logger from 'redux-logger'
import rootReducer from "./reducers";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const middleware = [];
if (process.env.NODE_ENV === "development") {
  middleware.push(logger);
}
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(reduxThunk)),
);
export const persister = persistStore(store);
// export default { store, persister };
