import * as types from "./types";
import _ from "lodash";
const INITIAL_STATE = [];
const AboutUs = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_CONTACT_US:
      return { ..._.mapKeys(action.payload, "id") };

    default:
      return state;
  }
};

export default AboutUs;
