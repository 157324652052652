import React from "react";

const Loading = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div className="property-page-area bg-black pd-top-120 pd-bottom-90 ">
      <div className="container">
        <div className="property-details-top bg-black pd-bottom-70">
          <div className="property-details-top-inner bg-black">
            <div className="row bg-black">
              <div className="col-lg-12">
                <img src={publicUrl + "assets/img/loading.gif"} alt="img" />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
