import * as types from "./types";
import _ from "lodash";
const INITIAL_STATE = [];
const AppInfo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_APP_INFO:
      return { ..._.mapKeys(action.payload, "id") };

    default:
      return state;
  }
};

export default AppInfo;
