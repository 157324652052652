export const SET_OFFERING_TYPE = "SET_OFFERING_TYPE";
export const SET_PROPERTY_TYPE = "SET_PROPERTY_TYPE";
export const SET_CATEGORY_TYPE = "SET_CATEGORY_TYPE";
export const SET_PRICE = "SET_PRICE";
export const SET_PRICE_MIN = "SET_PRICE_MIN";
export const SET_PRICE_MAX = "SET_PRICE_MAX";
export const SET_CITY = "SET_CITY";
export const SET_SIZE = "SET_SIZE";
export const SET_BEDROOM = "SET_BEDROOM";
export const SET_BATHROOM = "SET_BATHROOM";
export const SET_FURNISHED = "SET_FURNISHED";
export const SET_PRICE_ON_APPLICATION = "SET_PRICE_ON_APPLICATION";
