import * as types from "./types";

const categories = [
  {
    label: "Community",
    value: "C",
  },
  {
    label: "Project",
    value: "P",
  },
  {
    label: "Building Area",
    value: "BA",
  },
];
const Categories = (state = categories, action) => {
  switch (action.type) {
    case types.FETCH_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};

export default Categories;
