import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import HomeV1 from "./components/home-v1";
// import HomeV2 from "./components/home-v2";
// import HomeV3 from "./components/home-v3";
// import PropertyDetails from "./components/property-details";
// import PropertyGrid from "./components/property-grid";
// import Property from "./components/property";
// import About from "./components/about";
//  import Team from "./components/team";
// import SignIn from "./components/sign-in";
// import SignUp from "./components/sign-up";
// import AddProperty from "./components/add-property";
// import Contact from "./components/contact";
// import LatestNews from "./components/latest-news";
// import Blog from "./components/blog";
// import NewsDetails from "./components/news-details";
// import BlogDetails from "./components/blog-details";
// import ComingSoon from "./components/coming-soon";
import Loading from "./components/global-components/loading";
const HomeV1 = lazy(() => import("./components/home-v1"));
const PropertyDetails = lazy(() => import("./components/property-details"));
const PropertyGrid = lazy(() => import("./components/property-grid"));
const About = lazy(() => import("./components/about"));
const Contact = lazy(() => import("./components/contact"));
const LatestNews = lazy(() => import("./components/latest-news"));
const NewsDetails = lazy(() => import("./components/news-details"));
const ComingSoon = lazy(() => import("./components/coming-soon"));
const Staff = lazy(() => import("./components/staff"));
const Page404 = lazy(() => import("./components/global-components/page-404"));

const router = () => {
  return (
    // <Suspense fallback={<div />}>
     <Suspense fallback={<Loading />}> 
      <Router basename="/">
        <Switch>
          <Route exact path="/" component={HomeV1} />
          {/* <Route exact path="/" component={DemoPage} /> */}
          {/* <Route path="/home-v2" component={HomeV2} /> */}
          {/* <Route path="/home-v3" component={HomeV3} /> */}
          <Route path="/property-details/:id" component={PropertyDetails} />
          <Route path="/property-listing" component={PropertyGrid} />
          <Route path="/latest-projects" component={PropertyGrid} />
          <Route path="/featured-listing" component={PropertyGrid} />
          <Route path="/news" component={LatestNews} />
          {/* <Route path="/property-grid" component={PropertyGrid} /> */}
          {/* <Route path="/property" component={ComingSoon} /> */}
          {/* <Route path="/about" component={ComingSoon} /> */}
          <Route path="/staff" component={Staff} />
          <Route path="/sign-in" component={ComingSoon} />
          <Route path="/sign-up" component={ComingSoon} />
          <Route path="/add-property" component={ComingSoon} />
          {/* <Route path="/contact" component={ComingSoon} /> */}
          <Route path="/blog" component={ComingSoon} />
          <Route path="/blog-details" component={ComingSoon} />
          <Route path="/news-details/:id" component={NewsDetails} />

          <Route path="/property" component={PropertyGrid} />
          {/* <Route path="/property" component={Property} /> */}
          <Route path="/about" component={About} />
          {/* <Route path="/team" component={Team} /> */}
          {/* <Route path="/sign-in" component={SignIn} /> */}
          {/* <Route path="/sign-up" component={SignUp} /> */}
          {/* <Route path="/add-property" component={AddProperty} /> */}
          <Route path="/contact" component={Contact} />
          <Route path="*" component={Page404} />
          {/* <Route path="/blog" component={Blog} /> */}
          {/* <Route path="/blog-details" component={BlogDetails} /> */}
        </Switch>
      </Router>
    </Suspense>
  );
};

export default router;
