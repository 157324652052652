import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { reducer as formReducer } from "redux-form";
import storage from "redux-persist/lib/storage";
import Listings from "./properties/reducer";
import GlobalValues from "./global/reducer";
import PropertyTypes from "./propertyTypes/reducer";
import OfferingTypes from "./offeringTypes/reducer";
import PropertyFilters from "./propertyFilters/reducer";
import Categories from "./categories/reducer";
import Agents from "./agents/reducer";
import News from "./news/reducer";
import Staff from "./staff/reducer";
import AboutUs from "./aboutUs/reducer";
import ContactUs from "./contactUs/reducer";
import AppInfo from "./appInfo/reducer";
const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  form: formReducer,
  Listings,
  GlobalValues,
  PropertyTypes,
  OfferingTypes,
  Categories,
  PropertyFilters,
  Agents,
  News,
  Staff,
  AboutUs,
  ContactUs,
  AppInfo,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export default persistedReducer;
