import * as types from "./types";
import _ from "lodash";
const INITIAL_NEWS = [
  {
    Id: 1,
    Title:
      "DUBAI RECORDS OVER AED1 BILLION IN REAL ESTATE TRANSACTIONS ON ONE DAY",
    SubTitle:
      "The emirate’s real estate market has been on an upward spiral in the recent weeks, recording transactions over $1 billion in several days, as it continues with its post-pandemic recovery",
    Description1: `The Dubai real estate market recorded transactions to the tune of over AED1 billion on Wednesday, July 13, according to data released by Dubai’s Land Department (DLD).`,
    Description2: `The transactions included 304 sales deals amounting to AED735.85 million, 77 mortgage deals of AED263.54 million and 12 gift deals worth AED24.3 million.`,
    Description3: `The sales included 282 villas and apartments worth AED651.17 million, and 22 land plots worth AED84.68 million, while mortgages included 65 villas and apartments worth AED136.77 million and 12 land plots valued at AED126.77 million.
    The Dubai real estate market has been on an upward spiral in recent weeks, recording transactions over $1 billion in several days, as it continues with its post-pandemic recovery.
    `,
    Description4: `The emirate’s real estate market recorded transactions of around $1.06 billion (AED 3.9 billion) in value on Thursday last week, which included 563 sales deals amounting to AED 3.46 billion and 96 mortgage transactions amounting to AED 91.24 million.
    Dubai witnessed a record AED 22.7 billion real estate sales transactions last month – highest sales figures in the last 13 years – and reaching almost 71 percent of the total 2021 sales volume.
    `,
    Description5: `The June transaction figures were 32.88 percent higher in volumes and 24.21 percent in value terms on a sequential basis, compared to May 2022.
    For the April-June quarter, Dubai’s property market transactions amounted to AED 59.29 billion, up by 6.81 percent compared to Q1 2022.
    `,
    Image: "assets/img/news/1.jpg",
    Date: "20 Nov, 2022",
    PostedBy: "IBDAA",
    Source: "Arabian Business",
    IsActive: true,
  },
  {
    Id: 2,
    Title: `UAE GOLDEN VISA NOW AVAILABLE DIRECTLY FROM REAL ESTATE DEVELOPERS`,
    SubTitle: `UAE-based real estate developers are looking to make the most of the Golden Visa scheme by offering attractive Golden Visa benefits to eligible customers, including shouldering the cost of obtaining a UAE Golden Visa`,
    Description1: `The Dubai real estate market recorded AED 22.7 billion sales in June – highest sales figures in the last 13 years – and reaching almost 71 percent of the total 2021 sales volume, Dubai Land Development (DLD) data revealed, and UAE real estate developers are looking to take advantage of the Golden Visa scheme as property demand grows.`,
    Description2: `Those purchasing property off-plan or with a mortgage now have the option to obtain 10-year residency in the UAE through a Golden Visa as part of the country’s plan to attract further investments and boost the benefits for long-term residents.`,
    Description3: `The Golden Visa regulations are a “game-changer” for the UAE’s real estate industry, building on the momentum it’s garnered over the past year and a half.`,
    Description4: `“The fact that you can get a Golden Visa now when purchasing a secondary or off-plan property for AED2million which can be mortgaged pretty much covers all common scenarios for not only investors but also for UAE residents who own property,” said Lynnette Sacchetto, a UAE-based real estate industry consultant.`,
    Description5: `The flexible regulations to obtain the 10-year residency through real estate will encourage further foreign investments in the country, industry stakeholders have previously told Arabian Business.`,
    Image: `assets/img/news/2.webp`,
    Date: `24 Dec, 2022`,
    PostedBy: `IBDAA`,
    Source: `Arabian Business`,
    IsActive: true,
  },
  {
    Id: 3,
    Title: `DUBAI: SOON, PAY YOUR RENT THROUGH DIRECT DEBIT INSTEAD OF CHEQUES`,
    SubTitle: `Tenants will be able to avail of flexible payment plans from the landlords`,
    Description1: `In a new partnership between Dubai Land Department and Emirates NBD, rental cheque payments will soon be automated and digitized using the Central Bank of the UAE’s Direct Debit System (UAEDDS). This means that tenants can make payments through their bank accounts instead of issuing cheques.`,
    Description2: `This practice will benefit both landlords and property management companies - who will no longer have to manage post-dated cheques manually - as well as tenants, who can avail of flexible payment plans.`,
    Description3: `The new initiative is aligned with the Dubai Government’s vision for paperless payment ecosystems as well as with the Dubai 10X initiative.
    Individual investors from overseas looking to purchase property in Dubai can also open non-resident savings accounts with Emirates NBD, and manage purchase and rent collection through them.
    `,
    Description4: `His Excellency Sultan Butti bin Mejren, Director General of DLD said, “The real estate industry in the UAE is one of the best and most competitive in the world, and as part of our mission to present a regulatory environment in service of tenants and property owners, we have partnered with Emirates NBD in line with our efforts to fully digitalize our services as per the Dubai Paperless Strategy.
    Such collaborations will help facilitate and streamline the processes involved in the property market by employing progressive machinations on the path towards replacing the legacy systems in place.”
    `,
    Description5: `Hesham Abdulla Al Qassim, Vice Chairman and Managing Director of Emirates NBD, said, “Emirates NBD is delighted to partner with Dubai Land Department on these strategic initiatives that will help in providing landlords and tenants with an easier, automated rent collection and management system as well as facilitate property purchases by new non-resident investors.”`,

    Image: `assets/img/news/3.jfif`,
    Date: `01 Jan, 2023`,
    PostedBy: `IBDAA`,
    Source: `Khaleej Times`,
    IsActive: true,
  },
  {
    Id: 4,
    Title: `DUBAI'S READY HOME SALES RISE SHARPLY TO $2.3BN IN FEBRUARY`,
    SubTitle: ``,
    Description1: `The value of ready home transactions in Dubai more than doubled annually to Dh8.5 billion ($2.3bn) in February, according to Egyptian investment bank EFG Hermes.
    The increase was a result of a UAE property market rebound driven by government initiatives and Expo 2020 Dubai.
    `,
    Description2: `Total residential activity rose 34 per cent a year to Dh15.5bn in February, with 6,913 units sold. This was primarily fuelled by a three-time surge in the value of off-plan sales and a 107 per cent rise in the sale of move-in ready homes, the report said, citing Reidin data.
    A total of 2,399 off-plan units worth Dh4.24bn were sold in February, compared with 955 homes valued at Dh1.26bn during same period last year.
    `,
    Description3: `“The most active areas in Dubai for off-plan sales were Mohammed bin Rashid City, Dubailand, Dubai Marina, Business Bay and Downtown Dubai,” EFG Hermes said.
    The UAE property market has recovered from the coronavirus-induced slowdown on the back of government initiatives such as residency permits for retirees and remote workers, as well as the expansion of the 10-year golden visa programme.
    Measures to contain the spread of the pandemic, such as the UAE’s widespread vaccination programme, have also helped the real estate market to recover.
    `,
    Description4: `The value of property deals in Dubai more than doubled last year and broke a 12-year record in terms of sales transactions.
    The emirate registered 61,241 sales deals worth Dh151.07bn in 2021, making it the best year for total transactions since 2013 and the highest in terms of value since 2009, said listings portal Property Finder.
    Affordable home sales accounted for 48 per cent of total residential transactions in February while budget and luxury home sales made up 31 per cent and 21 per cent of the total mix, respectively, EFG Hermes said.
    `,
    Description5: `Villas accounted for 36 per cent of the value of all transactions while apartments contributed 31 per cent and land sales added 29 per cent, the report said.
    Residential sales prices in Dubai rose 41.3 per cent annually to an average of Dh1,612 per square foot on higher prices across all segments.
    Palm Jumeirah registered the biggest increase annually, with the price per square foot rising to Dh2,480, from Dh1,322, while Bur Dubai recorded the biggest drop in price — to Dh1,304, from Dh2,671, EFG Hermes reported.
    In terms of February's rental performance, Downtown Dubai led the recovery while communities such as the International Media Production Zone, Sports City and Dubai Silicon Oasis were the worst performers, the report found.
    In the Dubai luxury market, total activity rose 156 per cent annually to Dh3.3bn. The top five areas by transaction value for luxury property were Palm Jumeirah, Downtown Dubai, Dubai Harbour, Dubai Creek Harbour and Jumeirah Bay Island.
    
    There was a strong pick-up in activity in the affordable property segment in February, driven by sales of ready units.
    The transaction value of cheaper homes rose 26 per cent a year to Dh7.4bn, EFG Hermes said, with MBR City, Dubai Marina, Business Bay, Damac Lagoons and Arabian Ranches making up the top five areas by transaction value.
    The budget property market grew 9 per cent annually to Dh4.8bn, also on growing ready unit sales.
    Dubailand accounted for 41 per cent of total activity in the budget segment while Al Furjan, Tilal Al Ghaf, Jumeirah Village Circle and Jumeirah Golf Estates were the other main areas for the segment, the report said.
    `,
    Image: `assets/img/news/4.jpg`,
    Date: `04 Jan, 2023`,
    PostedBy: `IBDAA`,
    Source: ``,
    IsActive: true,
  },
  {
    Id: 5,
    Title: `DUBAI: 52,000 APARTMENTS, VILLAS WORTH DH114.2 BILLION SOLD IN 2021`,
    SubTitle: ``,
    Description1: `Ultra-prime homes sales in Dubai that cost more than $10 million have reached record levels, according to a new whitepaper released by global property consultancy Knight Frank.
    In 2021, Dubai’s residential market recorded over 52,000 apartment and villa transactions, totalling Dh114.2 billion, more than the total for 2019 and 2020 combined.
    “The international elite continue to have an insatiable appetite for Dubai’s most luxurious homes, and this has driven $10 million home sales to an all-time record high of 93 in 2021, more than the last 5 years combined,” said Faisal Durrani, Partner – Head of Middle East Research at Knight Frank.
    `,
    Description2: `“In fact, 2021 accounts for 39 per cent of all $10 million home sales in Dubai since 2010. This underscores the phenomenal depth of demand at the top end of the market. And these UHNWI continue to pour into the city’s luxury residential market from locations such as India, Russia and Europe, including buyers from Switzerland and Monaco,” he added.
    As a result, four prime areas – Emirates Hills, Jumeirah Bay Island and the Palm Jumeirah – have together seen house prices climb by 44.4 per cent in the 12-months to the end of September 2021.
    Knight Frank’s analysis revealed that the picture is however somewhat different in the more mainstream market, particularly when it comes to apartments. The pandemic continues to fuel the race for space as working habits shift.
    Larger homes remain in high demand, and this is reflected in the fact that villa transactions continue to climb. Indeed, in Q4, the market registered a 29 per cent quarterly increase in the number of villas sold.
    Villa prices have jumped nearly 22 per cent since the start of the pandemic, while apartment values have not had the same increases, over the same period, Knight Frank’s research reveals.
    `,
    Description3: `Is it another bubble?
    “The big question on everyone’s minds is whether this is the start of another price-bubble. And the answer is: it’s complicated, but probably not. It really depends on which price points you look at. While supply remains a perennial downside risk, total stock in Prime Dubai forms a fraction of the wider market,” Durrani said, adding that in 2022, just 3.6 per cent of what’s planned will be delivered in Prime submarkets and this is where demand continues to intensify, suggesting there is still room for further price expansion in this segment of the market.
    “Overall, prices clearly cannot rise indefinitely and more buyers in the mainstream market are pausing to ask if prices represent ‘good value’. Evidence already suggests that the 21.2 per cent villa price growth of 2021 is starting to slow. Price growth is likely to slow this year, there are no two ways about it,” he said.
    `,
    Description4: `The Prime and super-prime end of the market will, however, outperform as luxury and uber-luxury supply remains so negligible and Dubai’s handling of the pandemic has fostered an overwhelming sense of positivity which we see reflected in the market and economic performance, Durrani said.
    Andrew Cummings, partner – Head of Prime Residential at Knight Frank Middle East, added In December 2020 Dubai was the world’s first major city to reopen its borders, following a single six-week lockdown, sending a very strong message to international travellers and investors. “In such a sentiment-driven market, this has had unintended, but positive consequences Several record-breaking deals have been recorded since the start of the pandemic as a result of this flood of UHNWI-linked capital,” he said.
    `,
    Description5: `ALSO READ:
    •	Palm Jumeirah, Damac Hills and Dubai South among popular villa communities for rent
    •	Dubai: Meet 3 residents who bought homes in the city to secure their future
    •	Dubai luxury home prices rise over 44% in 2021, fastest among top 100 cities
    “Indeed, a new record was set last summer with the $32.9 million sales of a villa on Jumeirah Bay Island to a European buyer. And prior to the sale of the Jumeirah Bay Island mansion, the most expensive home to sell in 2021 was on the Palm Jumeirah, where One100 Palm sold for over $30 million last Spring. Land plots too have registered record deal sizes, with a double plot on Jumeirah Bay Island selling for Dh150 million in January 2022 – the highest price on record for land on the island,” added Cummings.
    `,
    Image: `assets/img/news/5.jfif`,
    Date: `02 Feb, 2023`,
    PostedBy: `IBDAA`,
    Source: `waheedabbas@khaleejtimes.com`,
    IsActive: true,
  },
  {
    Id: 6,
    Title: `NAKHEEL ADDS TOWNHOUSES TO ITS COLLECTION OF UPSCALE HOMES`,
    SubTitle: ``,
    Description1: `Following the recent release and success of villas at the redeveloped Jebel Ali Village launched in November last year, master developer Nakheel has launched a new collection of luxurious three- and four-bedroom townhouses at the gated community.`,
    Description2: `Ranging from 2,882 to 3,386 square feet, the bright and roomy townhouses feature timeless interiors with high-quality finishing and a choice of light and dark colour schemes, as well as a ground floor guest suite, terrace, en-suite bathrooms, built-in wardrobes, a maid’s room, a laundry room and spacious garage for parking two cars.`,
    Description3: `As one of Dubai’s most treasured areas transforms into an upscale, tranquil oasis, Jebel Ali Village when complete, will span 80 hectares of lush green spaces, large open parks and water features, with urban community amenities, including a jogging track, a biking trail, a community vegetable farm, swimming pools, kids’ play areas and sports facilities such as a five-a-side football pitch, Padel tennis court and basketball court. Jebel Ali Recreation Club, the community’s existing dining, fitness and leisure destination, continues to remain a popular venue for the community.`,
    Description4: `A Nakheel spokesman said the launch of villas at Jebel Ali Village saw unprecedented demand for new homes at this cherished community.
    “We are now adding townhouses to provide more opportunities to be a part of the legacy this vibrant community holds. The redeveloped community’s world-class amenities, including green outdoor spaces and extensive sports facilities, highlights our commitment to providing better, healthier and active lifestyles for the residents of Dubai,” he said.
    `,
    Description5: `Strategically located next to Sheikh Zayed Road and Ibn Battuta Mall, Jebel Ali Village is minutes away from a host of entertainment destinations, educational facilities, and places of worship including a mosque, gurudwara and church, with Abu Dhabi just an hour’s drive away.`,

    Image: `assets/img/news/6.jpg`,
    Date: `10 Feb, 2023`,
    PostedBy: `IBDAA`,
    Source: `NAKHEEL`,
    IsActive: true,
  },
  {
    Id: 7,
    Title: `BUY PROPERTIES IN DUBAI - INVEST IN YOUR FUTURE`,
    SubTitle: ``,
    Description1: `Dubai is a city that has been experiencing rapid growth and development in recent years, making it a prime destination for investors looking to buy properties in Dubai. From luxurious high-rise apartments to sprawling villas and commercial properties, Dubai has a diverse range of options for those looking to invest in their future. In this blog, we'll explore why buying properties in Dubai is a smart investment decision and provide some tips to help you make the most of your investment.<br><br>
    <b>Booming Real Estate Market</b><br>
    <p>Dubai has one of the fastest-growing real estate markets in the world, with high demand for properties in various sectors. The city's infrastructure and economic growth have attracted many international investors, resulting in a surge in the property market. As a result, property values have been steadily increasing, making it a great time to invest in Dubai's real estate market.</p>
    <br>
    <b>Tax Benefits</b><br>
    <p>Dubai offers several tax benefits to investors, making it a popular destination for those looking to invest in property. One of the biggest advantages is that there are no property taxes in Dubai, which means that investors can enjoy higher profits on their investments. Additionally, the city offers a low tax rate, which means that investors can keep more of their earnings.</p>
    <br>
    <b>High Rental Yields</b><br>
    <p>Dubai's booming tourism industry has resulted in high demand for rental properties in Dubai. This has led to high rental yields, with some properties generating returns of up to 8-10%. This means that investors can earn a significant passive income from their investments, making it a great option for those looking to build wealth over time.</p>
    <br>
    <b>Diverse Investment Options</b>
    <p>Dubai has a diverse range of investment options, with properties available in various sectors such as residential, commercial, and industrial. This means that investors can choose the sector that best suits their investment goals and budget. For instance, those looking for long-term rental income can invest in residential properties, while those looking for short-term profits can invest in commercial properties.</p>
    <br>
    <b>Growing Economy</b>
    <p>Dubai has a rapidly growing economy, making it an attractive destination for businesses and investors. The city is home to many multinational corporations, and its strategic location makes it an ideal hub for trade and commerce. This has resulted in steady economic growth, which is expected to continue in the future, making it a great place to invest in property.</p>
<br>
<b>Tips for Investing in Properties in Dubai</b>
<p>If you're considering investing in properties in Dubai, here are some tips to help you make the most of your investment:</p>
<p><b>Do Your Research:</b> Before investing in any property, it's important to do your research and understand the market dynamics, property values, and rental yields. This will help you make an informed decision and choose the best property for your investment goals.</p>
<p><b>Work with a Reputable Real Estate Agent:</b> Working with a reputable real estate agent can help you navigate the complex Dubai real estate market and find properties that meet your specific needs and preferences. They can also help you negotiate favorable terms and ensure that all necessary paperwork and legal requirements are taken care of.</p>   
<p><b>Consider Location:</b> The location of the property is one of the most important factors to consider when investing in Dubai's real estate market. Choose a property in a prime location that is close to amenities such as schools, hospitals, shopping malls, and public transportation.</p>
<p><b>Analyze the Property's Condition: </b>Inspect the property thoroughly before making an investment decision. Check for any damages, repairs, or maintenance issues that need to be addressed. This will help you avoid any unexpected expenses after making the purchase.</p>
<p><b>Set Realistic Goals:</b> Set realistic goals for your investment, such as the expected rental yield, capital appreciation, and investment horizon. This will help you make an informed decision and choose a property that aligns with your investment goals.</p>
<b>Research the Developer</b>

<p>Before you decide to invest in a property in Dubai, it's important to research the developer. Make sure that they have a good reputation and track record of completing projects on time and within budget. You can check the developer's track record on the Dubai Land Department website or through other reputable sources.</p>

<b>Get Professional Help</b>

<p>Investing in real estate can be complex and challenging, especially if you're not familiar with the local market. That's why it's important to seek professional help from a reputable real estate agent or broker. They can guide you through the process, help you find the right property, negotiate a good price, and ensure that all legal requirements are met.</p>

<b>Consider the Location</b>

<p>Location is a key factor when it comes to buying property in Dubai. Consider factors such as proximity to schools, shopping centers, transportation links, and other amenities. Properties in prime locations tend to appreciate in value more quickly, making them a good investment.</p>

<b>Look at the Payment Plan</b>

<p>If you're buying a property off-plan, it's important to look at the payment plan. Most developers offer flexible payment plans, allowing you to pay in installments over a period of time. Make sure that you understand the payment schedule, as well as any fees or charges that may be associated with the payment plan.</p>

<b>Plan for Additional Costs</b>

<p>When buying property in Dubai, it's important to plan for additional costs such as service charges, maintenance fees, and other expenses. These costs can vary depending on the property and the location, so it's important to factor them into your budget.</p>

<b>Be Prepared for the Buying Process</b>

<p>Buying property in Dubai can be a lengthy process, so it's important to be prepared. Make sure that you have all the necessary documents, including a valid passport and visa, and be prepared to provide proof of funds. Working with a reputable real estate agent or broker can help streamline the process and ensure that everything is in order.</p>

<b>Take Your Time</b>

<p>Buying property in Dubai is a big decision, so it's important to take your time and do your research. Don't rush into anything, and make sure that you're comfortable with the property, the developer, and the location before making a commitment.</p>

<p>In conclusion, buying property in Dubai can be a smart investment for those looking to secure their financial future. By following these tips and working with a reputable real estate agent or broker, you can find the perfect property and make a smart investment for the future.</p>

`,
    Description2: ``,
    Description3: ``,
    Description4: ``,
    Description5: ``,

    Image: `assets/img/news/7.jpg`,
    Date: `20 Feb, 2023`,
    PostedBy: `IBDAA`,
    Source: `NAKHEEL`,
    IsActive: true,
  },
  {
    Id: 8,
    Title: `WHY INVEST IN DUBAI CREEK HARBOUR`,
    SubTitle: ``,
    Description1: `Dubai has always been a city of dreams, with its towering skyscrapers, opulent shopping destinations, and world-class amenities. The latest addition to this glittering landscape is Dubai Creek Harbour, a master-planned development that promises to raise the bar for luxury living in the city. Dubai Creek Harbour is located just minutes away from downtown Dubai, offering residents easy access to the city's thriving business and cultural hub. This stunning development is home to some of the most luxurious properties in the city, including apartments for sale that are designed to offer the ultimate comfort, style, and convenience.
    <p>At Dubai Creek Harbour, you'll find a range of properties for sale, including Dubai Creek Residences, Address Harbour Point, Harbour Views, Creekside, and Creek Rise. Each of these properties offer its own unique blend of luxury and comfort, making it easy for you to find the perfect home that meets your needs and lifestyle.</p>
    <p>What you can expect from a home in Dubai Creek Harbour:</p>
    <ul>
    <li>Modern amenities</li>
    <li>State-of-the-art kitchens</li>
    <li>Luxurious bathrooms</li>
    <li>Spacious living areas</li>
    <li>Breathtaking views of the skyline</li>
    <li>World-class recreational and leisure facilities</li>
    </ul>
    <p>When it comes to location, Dubai Creek Harbour is simply unrivaled. The development is located just minutes away from downtown Dubai, offering residents easy access to the city's bustling business and cultural hub. Whether you're looking for the excitement of the city or the tranquility of the waterfront, Dubai Creek Harbour has something for everyone.</p>
    <p>In conclusion, Dubai Creek Harbour is the epitome of luxury living. With its range of luxurious properties, world-class amenities, and prime location, it is the perfect place for those seeking the ultimate in urban living. Whether you're looking to buy an apartment for sale or invest in a property, Dubai Creek Harbour is a wise choice that is sure to deliver long-term value.</p>
     
    <b>WHY DOWNTOWN DUBAI IS ONE OF THE HOTTEST NEIGHBORHOODS IN DUBAI</b>
    <p>Downtown Dubai is a bustling, upscale neighborhood located in the heart of Dubai, UAE. It is known for its luxurious lifestyle, iconic skyscrapers, and vibrant business atmosphere. The area is home to some of the city's most well-known landmarks, including the Burj Khalifa, the world's tallest building, and the Dubai Mall, one of the largest shopping centers in the world. In addition to these attractions, Downtown Dubai is also home to a variety of restaurants, cafes, and bars, as well as a number of parks and open spaces.</p>
    <p>In addition to being a great place to live, Downtown Dubai is also an attractive location for businesses. The neighborhood is home to many of the city's largest corporations and financial institutions, and its central location makes it easy to access other parts of the city. Additionally, the Dubai International Financial Center (DIFC), which is located in the heart of the neighborhood, provides a hub for financial and business services.</p>
     
    <p>For those looking to buy property in the area, there are many options available. From modern apartments to spacious penthouses, there is a wide range of Properties for sale in Downtown Dubai. Choose from the most deluxe options with 8 Boulevard Walk, The Address Residences, Opera Grand, and many more luxurious apartments for sale. These properties are often designed with luxury and comfort in mind, and many feature state-of-the-art amenities such as swimming pools, gyms, and concierge services.</p>
    <p>Overall, Downtown Dubai is a prime location for both residents and businesses, offering a luxurious lifestyle, world-class amenities, and a thriving business community. If you're looking for a dynamic, upscale neighborhood with a lot to offer, Downtown Dubai is definitely worth considering.</p>
    <p>Dubai has always been a city of dreams, with its towering skyscrapers, opulent shopping destinations, and world-class amenities. The latest addition to this glittering landscape is Dubai Creek Harbour, a master-planned development that promises to raise the bar for luxury living in the city. Dubai Creek Harbour is located just minutes away from downtown Dubai, offering residents easy access to the city's thriving business and cultural hub. This stunning development is home to some of the most luxurious properties in the city, including apartment for sale that are designed to offer the ultimate comfort, style, and convenience.</p>
    <p>At Dubai Creek Harbour, you'll find a range of properties for sale, including Dubai Creek Residences, Address Harbour Point, Harbour Views, Creekside, and Creek Rise. Each of these properties offer its own unique blend of luxury and comfort, making it easy for you to find the perfect home that meets your needs and lifestyle.</p>
    <p>What you can expect from a home in Dubai Creek Harbour:</p>
    <ul>
    <li>Modern amenities</li>
    <li>State-of-the-art kitchens</li>
    <li>Luxurious bathrooms</li>
    <li>Spacious living areas</li>
    <li>Breathtaking views of the skyline</li>
    <li>World-class recreational and leisure facilities</li>
    </ul>
    <p>When it comes to location, Dubai Creek Harbour is simply unrivaled. The development is located just minutes away from downtown Dubai, offering residents easy access to the city's bustling business and cultural hub. Whether you're looking for the excitement of the city or the tranquility of the waterfront, Dubai Creek Harbour has something for everyone.</p>
    <p>In conclusion, Dubai Creek Harbour is the epitome of luxury living. With its range of luxurious properties, world-class amenities, and prime location, it is the perfect place for those seeking the ultimate in urban living. Whether you're looking to buy an apartment for sale or invest in a property, Dubai Creek Harbour is a wise choice that is sure to deliver long-term value.</p>
     
    <b>WHY DOWNTOWN DUBAI IS ONE OF THE HOTTEST NEIGHBORHOODS IN DUBAI</b>
    <p>Downtown Dubai is a bustling, upscale neighborhood located in the heart of Dubai, UAE. It is known for its luxurious lifestyle, iconic skyscrapers, and vibrant business atmosphere. The area is home to some of the city's most well-known landmarks, including the Burj Khalifa, the world's tallest building, and the Dubai Mall, one of the largest shopping centers in the world. In addition to these attractions, Downtown Dubai is also home to a variety of restaurants, cafes, and bars, as well as a number of parks and open spaces.</p>
    <p>In addition to being a great place to live, Downtown Dubai is also an attractive location for businesses. The neighborhood is home to many of the city's largest corporations and financial institutions, and its central location makes it easy to access other parts of the city. Additionally, the Dubai International Financial Center (DIFC), which is located in the heart of the neighborhood, provides a hub for financial and business services.</p>
     
    <p>For those looking to buy property in the area, there are many options available. From modern apartments to spacious penthouses, there is a wide range of proerteies for sale in Downtown Duab. Choose from the most deluxe options with 8 Boulevard Walk, The Address Residences, Opera Grand, and many more luxurious apartments for sale.These properties are often designed with luxury and comfort in mind, and many feature state-of-the-art amenities such as swimming pools, gyms, and concierge services.</p>
    <p>Overall, Downtown Dubai is a prime location for both residents and businesses, offering a luxurious lifestyle, world-class amenities, and a thriving business community. If you're looking for a dynamic, upscale neighborhood with a lot to offer, Downtown Dubai is definitely worth considering.</p>
    `,
    Description2: ``,
    Description3: ``,
    Description4: ``,
    Description5: ``,

    Image: `assets/img/news/8.jpg`,
    Date: `22 Feb, 2023`,
    PostedBy: `IBDAA`,
    Source: `NAKHEEL`,
    IsActive: true,
  },
  {
    Id: 9,
    Title: `DUBAI'S EMAAR PROPERTIES POSTS 80% SURGE IN 2022 PROFIT ON RECORD SALES`,
    SubTitle: ``,
    Description1: `Emaar Properties, Dubai's largest listed developer, has reported that its 2022 net profit rose 80 per cent on record real estate sales across its portfolio.

    <p>Net profit attributable to equity owners of the company for the 12 months ending December rose to Dh6.83 billion ($1.86 billion), from Dh3.8 billion a year earlier, the company's preliminary results showed on Tuesday in a filing to the Dubai Financial Market, where its shares are traded.</p>
    
    <p>This was driven by record property sales across its portfolio, which hit a record Dh35.1 billion last year, up more than four per cent from 2021's Dh33.7 billion.</p>
    
    <p>Revenue for the full year hit Dh24.93 billion on the back of stronger momentum in both the real estate sector and the overall economy. This was, however, down 11 per cent from the Dh27.9 billion it reported in 2021.</p>
    
    <p>Earnings before interest, taxes, depreciation and amortization (Ebitda) for 2022 rose 18 per cent to Dh9.8 billion, as the company was able to sustain revenue, control costs and improve margins, Emaar said.</p>
    
    <p>"We are very pleased with our accomplishments," Mohamed Alabbar, founder of Emaar, said.</p>
    
    <p>"Improvements in consumer confidence and overall business activity, especially in the real estate market, have benefitted all the company's operations over the past year, thanks to the strategic measures taken over the past two years."</p>
    
    <p>Shares of Emaar — which is best known for building Burj Khalifa, the world's tallest skyscraper in Dubai — settled 0.35 per cent higher at Dh5.77 at the close of trading at the DFM on Tuesday.</p>
    
    <p>Property prices across Dubai rose in 2022, as the market and wider economy strongly recovered from the coronavirus-induced slowdown.</p>
    
    <p>The emirate's property market also had a record-breaking year in 2022, registering 90,881 transactions, exceeding the previous high of 81,182 in 2009, property consultancy CBRE said in its recent Dubai Residential Market Snapshot report.</p>
    
    <p>Business activity in Dubai's non-oil private sector economy, meanwhile, remained robust in January, expanding for the ninth month in a row as consumer demand improved along with employment in the emirate, the S&P Global purchasing managers' index showed last week.</p>
    
    <p>Emaar has made moves to boost its strategy. In December, it increased its share capital by eight per cent to Dh8.83 billion and issued eight per cent more shares, to bring its total to 8.83 billion.</p>
    
    <p>Earlier last year, the company also increased the foreign ownership limit of its shares to 100 per cent, from 49 per cent, to capitalize on high interest from international investors.</p>
    
    <p>Emaar Development, the company's build-to-sell property development business majority-owned by Emaar Properties, also posted record sales in 2022 worth Dh30.7 billion, more than 13 per cent up from 2021's Dh27 billion.</p>
    
    <p>The unit contributed Dh11.5 billion in revenue and Dh4.2 billion in Ebitda.</p>
    
    <p>"Emaar Development continued the trend of increased sales and profitability," Mr Alabbar said.</p>
    
    <p>Emaar's international sales in 2022 hit Dh4.2 billion, down more than 17 per cent from the previous year's Dh5.1 billion, dragged by a 30 per cent decline in sales to Dh4.3 billion.</p>
    
    <p>Revenue in the unit, which was driven by successful operations in Egypt and India, contributed about 17 per cent to the group's total.</p>
    
    <p>Emaar Malls Management, which overseas the company's retail and shopping malls division, reported an eight per cent increase in 2022 revenue to Dh5.4 billion, compared to Dh5 billion a year earlier.</p>
    
    <p>Tenant sales surpassed pre-Covid levels, and occupancy rates at The Dubai Mall, Emaar's flagship asset, stood at 97 per cent, the company said.</p>
    
    <p>In its hospitality, leisure, entertainment and commercial leasing units, revenue surged 57 per cent to Dh3.4 billion in 2022.</p>
    
    <p>Emaar's hotels in the UAE performed well on higher average daily rates and a boost from the tourism sector, which was driven by global events such as Expo 2020 Dubai and the 2022 Fifa World Cup in Qatar.</p>
    
    <p>Emaar's recurring revenue-generating units, comprising malls, hospitality, leisure, entertainment and commercial leasing, posted Dh8.8 billion in revenue last year, which was a 23 per cent rise from 2021.</p>
    
    <p>"Across the board in our portfolio of retail, hospitality and entertainment, we are seeing demand for the exceptional communities and amenities we deliver," Mr Alabbar said.</p>
    
    <p>Source: Thenationalnews.com</p>
    
    `,
    Description2: ``,
    Description3: ``,
    Description4: ``,
    Description5: ``,

    Image: `assets/img/news/9.jpg`,
    Date: `24 Feb, 2023`,
    PostedBy: `IBDAA`,
    Source: `Thenationalnews.com`,
    IsActive: true,
  },
  {
    Id: 10,
    Title: `EXPLORE THE FINEST LUXURY PROPERTIES IN DUBAI`,
    SubTitle: ``,
    Description1: `Dubai is a city that has something for everyone, but it's particularly renowned for its luxury offerings. From stunning architecture to world-class amenities, the city's luxury properties are a reflection of the lavish lifestyle that the city is famous for. In this blog, we'll explore some of the finest luxury properties in Dubai that are the epitome of opulence.
    <p><b>Discover the Ultimate Luxury Experience: Finest Properties in Dubai</b></p>
    <p>Dubai is home to some of the most iconic and luxurious properties in the world. Whether you're looking for a spacious villa, a lavish penthouse, or a luxurious apartment, Dubai has something to offer. Here are some of the finest luxury properties in Dubai that offer the ultimate luxury experience:</p>
    <p>Burj Khalifa - The Burj Khalifa is the tallest building in the world and is home to some of the most luxurious apartments in Dubai. Living in the Burj Khalifa means experiencing the pinnacle of luxury, with stunning views of the city and world-class amenities at your doorstep.</p>
    <p>Palm Jumeirah - The Palm Jumeirah is a man-made island that offers some of the most luxurious villas in Dubai. These villas are spacious and opulent, with beachfront views that offer a sense of tranquility and exclusivity.</p>
    <p>Downtown Dubai - Downtown Dubai is the heart of the city, and it's home to some of the most luxurious apartments and penthouses in Dubai. With stunning views of the Burj Khalifa and access to world-class amenities, living in Downtown Dubai is the epitome of luxury.</p>
    <p>Emirates Hills - Emirates Hills is one of the most exclusive neighborhoods in Dubai, and it's home to some of the most luxurious villas in the city. These villas are surrounded by lush greenery and offer stunning views of the golf course, making them the perfect abode for those seeking the ultimate luxury lifestyle.</p>
    <p>In addition to these luxurious properties, Dubai is also home to some of the most exclusive attractions and amenities in the world. Here are some of the top attractions in Dubai that offer the ultimate luxury experience:</p>
    <b>Expert Tips For Navigating Dubai's Dynamic Luxury Real Estate Market</b>
    <p>Dubai Mall - The Dubai Mall is one of the largest shopping malls in the world and is home to some of the most luxurious brands and boutiques. The mall also features a stunning indoor waterfall, making it a must-visit attraction for luxury enthusiasts.</p>
    <p>Burj Al Arab - The Burj Al Arab is the world's most luxurious hotel, and it's home to some of the most opulent restaurants and bars in the city. With stunning views of the Arabian Gulf and access to world-class amenities, the Burj Al Arab is the ultimate luxury experience.</p>
    <p>Dubai Marina - Dubai Marina is a stunning waterfront area in Dubai, and it's home to some of the most luxurious yachts and boats in the world. A visit to the marina offers a glimpse into the lavish lifestyle that Dubai is renowned for.
    When it comes to buying or renting a luxury property in Dubai, it's important to keep in mind that the city's real estate market is constantly evolving. With new projects and developments being launched regularly, it's essential to stay up-to-date with the latest trends and offerings in order to make an informed decision.</p>
    <p>To help you navigate the Dubai real estate market, it's recommended to work with a reputable real estate agent who has in-depth knowledge of the city's luxury property market. A good agent can help you identify the right property that meets your needs, preferences, and budget, and guide you through the entire buying or renting process.</p>
    <b>Conclusion</b>
    <p>Dubai is a city that is synonymous with luxury and extravagance, and its real estate market reflects this. With its stunning architecture, world-class amenities, and exclusive attractions, Dubai offers a unique luxury experience that is unparalleled. Whether you're looking to buy or rent a luxury property in Dubai, there are plenty of options to choose from that cater to every taste and preference. So, if you're looking to experience opulence in the City of Gold, Dubai is the perfect destination for you.</p>
    
    `,
    Description2: ``,
    Description3: ``,
    Description4: ``,
    Description5: ``,

    Image: `assets/img/news/10.jpg`,
    Date: `26 Feb, 2023`,
    PostedBy: `IBDAA`,
    Source: `NAKHEEL`,
    IsActive: true,
  },
];

const News = (state = INITIAL_NEWS, action) => {
  switch (action.type) {
    case types.FETCH_NEWS:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    default:
      return _.mapKeys(state, "Id");
  }
};

export default News;
