import * as types from "./types";
import _ from "lodash";
const INITIAL_VALUES = [];
const properties = (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case types.FETCH_LISTING_XML_FEED:
      return _.mapKeys(action.payload, "Property_Ref_No");
    case types.FETCH_LISTING_JSON_FEED:
      return action.payload;
    case types.FETCH_PROPERTIES:
      return action.payload;
    case types.FETCH_PROPERTY:
      return action.payload;
    default:
      return state;
  }
};

export default properties;
